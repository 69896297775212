import React, { useEffect, lazy } from "react";
import { HashRouter, Route, Routes, useLocation } from "react-router-dom";
import Footer from "../component/footer/Main";
import { Suspense } from "react";

const Heiring = lazy(() => import("../component/employment/Main.js"));
const DepartmentOfHealthCare = lazy(() =>
  import("../component/Pages/departments/Departmentofhealthcare/Main")
);
const LMUVC = lazy(() => import("../component/Pages/About/lmu-vc"));
const DepartmentOfMedicalLaboratory = lazy(() =>
  import("../component/Pages/departments/Departmentofmedicallaboratory/Main")
);
const DepartmentOfPharmacyTechnology = lazy(() =>
  import("../component/Pages/departments/Departmentofpharmacytechnology/Main")
);
const DepartmentOfNursing = lazy(() =>
  import("../component/Pages/departments/Departmentofnursing/Main")
);
const About = lazy(() => import("../component/Pages/About/Main"));
const HNDOverView = lazy(() => import("../component/Pages/Academics/hnd/Main"));
const StraightBSCBTechOverView = lazy(() =>
  import("../component/Pages/Academics/straightbscbtech/Main")
);
const TopUpBSCBTechOverView = lazy(() =>
  import("../component/Pages/Academics/topupbscbtech/Main")
);
const MastersProgramOverView = lazy(() =>
  import("../component/Pages/Academics/mastersprogram/Main")
);
const Departments = lazy(() =>
  import("../component/Pages/Academics/departements/Main")
);
const Coursesdeatils = lazy(() => import("../component/coursedetails/Main"));
const Announcements = lazy(() =>
  import("../component/Pages/others/announcemnts-all/announcements/Main")
);
const AnnouncementDetail = lazy(() =>
  import("../component/Pages/eventdeatils/Main")
);
const Gallery = lazy(() => import("../component/Pages/gallery/Main"));
const Tuition = lazy(() => import("../component/Pages/tuitionfee/Main"));
const Faq = lazy(() => import("../component/Pages/Faq/Main"));
const Error = lazy(() => import("../component/Pages/error/Main"));
const NewsBlog = lazy(() => import("../component/Pages/newsblog/Main"));
const Contact = lazy(() => import("../component/Pages/contact/Main"));
const ApplicationForm = lazy(() =>
  import("../component/Pages/application-form/Main")
);
const CampusA = lazy(() =>
  import("../component/Pages/Campus Life/campus-a/Main")
);
const CampusB = lazy(() =>
  import("../component/Pages/Campus Life/campus-b/Main")
);
const StudentAccormodations = lazy(() =>
  import("../component/Pages/Campus Life/students-hostels/Main")
);
const Home = lazy(() => import("../component/Pages/home/Main"));
const LMUCoreValues = lazy(() =>
  import("./../component/Pages/About/lmu-core-values")
);
const LMUPolycies = lazy(() =>
  import("./../component/Pages/About/lmu-polycies")
);
const LMUPresident = lazy(() =>
  import("./../component/Pages/About/lmu-president")
);
const LMULecturers = lazy(() =>
  import("./../component/Pages/About/lmu-teching-staff")
);
const LMURecongnationAndAccreditation = lazy(() =>
  import("./../component/Pages/About/lmu-recognation-and-accreditaion")
);
const Schools = lazy(() =>
  import("../component/Pages/Academics/schools/schools")
);
const LMUAdministrationMain = lazy(() =>
  import("./../component/Pages/About/lmu-administration")
);
const LMUStudentGovernment = lazy(() =>
  import("./../component/Pages/About/lmu-student-governing-council")
);
const LMUHistory = lazy(() => import("./../component/Pages/About/lmu-history"));
const News = lazy(() => import("../component/Pages/others/news/Main.js"));
const SoftwareEng = lazy(() =>
  import("../component/Pages/softwareengineering/Main")
);
const HardwareEng = lazy(() =>
  import("../component/Pages/hardwarewareengineering/Main")
);
const DatabaseEng = lazy(() =>
  import("./../component/Pages/databaseengineering/Main")
);
const ComputerGraphicEng = lazy(() =>
  import("./../component/Pages/computergraphics/Main")
);
const TelecomEng = lazy(() =>
  import("./../component/Pages/computergraphics/Main")
);
const NetworkSercurity = lazy(() =>
  import("../component/Pages/networksercurity/Main")
);
const InformationTechSerc = lazy(() =>
  import("../component/Pages/informationand sercurity/Main")
);
const CISCO = lazy(() => import("./../component/Pages/cisco/Main"));
const EPS = lazy(() => import("../component/Pages/electricalpowersystem/Main"));
const Robotic = lazy(() => import("./../component/Pages/robotic/Main"));
const Electronics = lazy(() => import("./../component/Pages/robotic/Main"));
const CivilEngineering = lazy(() =>
  import("./../component/Pages/civilengineering/Main")
);
const PublicWorks = lazy(() => import("../component/Pages/publicworks/Main"));
const MechanicalEngineering = lazy(() =>
  import("./../component/Pages/mechanicalengineering/Main")
);
const BankingAndFinance = lazy(() =>
  import("../component/Pages/bankingand finance/Main")
);
const Accounting = lazy(() => import("../component/Pages/Accounting/Main"));
const Marketing = lazy(() => import("./../component/Pages/marketing/Main"));
const DigitalMarketing = lazy(() =>
  import("./../component/Pages/DigitalMarketing/Main")
);
const HumanResource = lazy(() =>
  import("../component/Pages/humanresource/Main")
);
const Projectmanagemnt = lazy(() =>
  import("./../component/Pages/projectmanagement/Main")
);
const TransportandLogistic = lazy(() =>
  import("../component/Pages/transportandlogistic/Main")
);
const ShippingManagement = lazy(() =>
  import("../component/Pages/shippingmanagement/Main")
);
const EducationDidatics = lazy(() =>
  import("../component/Pages/educationaldidatics/Main")
);
const VocationalGuidanceAndCounselling = lazy(() =>
  import("../component/Pages/vocationalguidanceandcounselling/Main")
);
const ProfessionalStudiesAndEdcationalmanagement = lazy(() =>
  import("../component/Pages/professionalstudiesandeducationalmanagement/Main")
);
const Law = lazy(() => import("../component/Pages/law/Main"));
const Journalism = lazy(() =>
  import("./../component/Pages/journalistandcorperatecommunicate/Main")
);
const TourismAndHospitality = lazy(() =>
  import("./../component/Pages/tourismandhospitality/Main")
);
const TourismAndTravel = lazy(() =>
  import("../component/Pages/tourismandtravel/Main")
);
const HomeEconomics = lazy(() =>
  import("../component/Pages/homeeconomic/Main")
);
const Nursing = lazy(() => import("../component/Pages/nursing/Main"));
const MedicalLaboratorySciences = lazy(() =>
  import("./../component/Pages/medicallaboratorysciences/Main")
);
const PharmacyTechnology = lazy(() =>
  import("./../component/Pages/pharmacytechnology/Main")
);
const Dentistry = lazy(() => import("./../component/Pages/dentistry/Main"));
const Midwifery = lazy(() => import("./../component/Pages/dentistry/Main"));
const CropProduction = lazy(() =>
  import("../component/Pages/cropproduction/Main")
);
const AnimalProduction = lazy(() =>
  import("../component/Pages/animalproductiontechnology/Main")
);
const FoodTechnology = lazy(() =>
  import("./../component/Pages/foodtechnology/Main")
);
const AgroPastoralEntrepreneurship = lazy(() =>
  import("./../component/Pages/agropastoralentrepreneurship/Main")
);
const LMURegistra = lazy(() => import("../component/Pages/About/lmu-registra"));
const Campus = lazy(() => import("../component/Menabout/Campus"));
const SchoolofEng = lazy(() =>
  import("../component/Pages/schoolofengineering/Main")
);
const SchoolofBusiness = lazy(() =>
  import("./../component/Pages/schoolofbusiness/Main")
);
const SchoolofMedicine = lazy(() =>
  import("../component/Pages/schoolofmedicine/Main")
);
const SchoolofAgriculture = lazy(() =>
  import("./../component/Pages/schoolofagriculture/Main")
);
const ShortPrograms = lazy(() =>
  import("../component/Pages/Academics/shortprograms/Main")
);
const AcademicCalenda = lazy(() =>
  import("../component/Pages/academiccalenda/Main")
);
const DepartmentOfComputerEngineering = lazy(() =>
  import(
    "./../component/Pages/departments/Departmentofcomputerengineering/Main"
  )
);
const DepartmentOfNetworkingAndTelecommunication = lazy(() =>
  import(
    "./../component/Pages/departments/Departmentofnetworkingandtelecommuniation/Main"
  )
);
const DepartmentOfElectricalEngineering = lazy(() =>
  import(
    "./../component/Pages/departments/Departmentofelectricalengineering/Main"
  )
);
const DepartmentOfCivilEngineering = lazy(() =>
  import("./../component/Pages/departments/Departmentofcivilengineering/Main")
);
const DepartmentOfMechanicalEngineering = lazy(() =>
  import(
    "./../component/Pages/departments/Departmentofmechanicalengineering/Main"
  )
);
const DepartmentOfFinance = lazy(() =>
  import("./../component/Pages/departments/Departmentoffinance/Main")
);
const DepartmentOfMarketing = lazy(() =>
  import("../component/Pages/departments/Departmentofmarketing/Main")
);
const DepartmentOfTransportandLogistics = lazy(() =>
  import(
    "../component/Pages/departments/Departmentoftransportandlogistics/Main"
  )
);
const DepartmentOfHumanResource = lazy(() =>
  import("../component/Pages/departments/Departmentofhumanresource/Main")
);
const DepartmentOfProjectmanagement = lazy(() =>
  import("../component/Pages/departments/Departmentofprojectmanagement/Main")
);
const DepartmentOfEducation = lazy(() =>
  import("./../component/Pages/departments/Departmentofeducation/Main")
);
const DepartmentOfLegalAffairs = lazy(() =>
  import("../component/Pages/departments/Departmentoflegalaffairs/Main")
);
const DepartmentOfJournalism = lazy(() =>
  import("../component/Pages/departments/Departmentofjournalism/Main")
);
const DepartmentOfTourism = lazy(() =>
  import("./../component/Pages/departments/Departmentoftoursim/Main")
);
const DepartmentOfHomeEconomics = lazy(() =>
  import("../component/Pages/departments/Departmentofhomeeconomic/Main")
);

function Index() {
  const location = useLocation();
  const path = location.pathname;
  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Suspense>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/lmu-department-of-medical-laboratory-sciences-detail"
          element={
            <Suspense>
              <DepartmentOfMedicalLaboratory />
            </Suspense>
          }
        />
        <Route
          path="/lmu-vice-chancellor"
          element={
            <Suspense>
              <LMUVC />
            </Suspense>
          }
        />
        <Route
          path="/lmu-department-of-health-care-detail"
          element={
            <Suspense>
              <DepartmentOfHealthCare />
            </Suspense>
          }
        />
        <Route
          path="/lmu-department-of-networking-and-telecomunication"
          element={
            <Suspense>
              <DepartmentOfNetworkingAndTelecommunication />
            </Suspense>
          }
        />
        <Route
          path="/lmu-department-of-pharmacy-technology"
          element={
            <Suspense>
              <DepartmentOfPharmacyTechnology />
            </Suspense>
          }
        />
        <Route
          path="/lmu-department-of-home-economics"
          element={
            <Suspense>
              <DepartmentOfHomeEconomics />
            </Suspense>
          }
        />
        <Route
          path="/lmu-department-of-human-resource"
          element={
            <Suspense>
              <DepartmentOfHumanResource />
            </Suspense>
          }
        />
        <Route
          path="/lmu-department-of-project-management"
          element={
            <Suspense>
              <DepartmentOfProjectmanagement />
            </Suspense>
          }
        />
        <Route
          path="/lmu-department-of-nursing"
          element={
            <Suspense>
              <DepartmentOfNursing />
            </Suspense>
          }
        />
        <Route
          path="/lmu-department-of-transport-and-logistics"
          element={
            <Suspense>
              <DepartmentOfTransportandLogistics />
            </Suspense>
          }
        />
        <Route
          path="/lmu-department-of-mechanical-engineering"
          element={
            <Suspense>
              <DepartmentOfMechanicalEngineering />
            </Suspense>
          }
        />
        <Route
          path="/lmu-department-of-civil-engineering"
          element={
            <Suspense>
              <DepartmentOfCivilEngineering />
            </Suspense>
          }
        />
        <Route
          path="/lmu-department-of-education"
          element={
            <Suspense>
              <DepartmentOfEducation />
            </Suspense>
          }
        />
        <Route
          path="/lmu-department-of-electrical-engineering"
          element={
            <Suspense>
              <DepartmentOfElectricalEngineering />
            </Suspense>
          }
        />
        <Route
          path="/lmu-department-of-legal-affairs"
          element={
            <Suspense>
              <DepartmentOfLegalAffairs />
            </Suspense>
          }
        />
        <Route
          path="/lmu-department-of-tourism"
          element={
            <Suspense>
              <DepartmentOfTourism />
            </Suspense>
          }
        />
        <Route
          path="/lmu-department-of-journalism"
          element={
            <Suspense>
              <DepartmentOfJournalism />
            </Suspense>
          }
        />
        <Route
          path="/lmu-department-of-computer-engineering"
          element={
            <Suspense>
              <DepartmentOfComputerEngineering />
            </Suspense>
          }
        />
        <Route
          path="/lmu-department-of-finance"
          element={
            <Suspense>
              <DepartmentOfFinance />
            </Suspense>
          }
        />
        <Route
          path="/lmu-department-of-marketing"
          element={
            <Suspense>
              <DepartmentOfMarketing />
            </Suspense>
          }
        />
        <Route
          path="/lmu-academic-calenda"
          element={
            <Suspense>
              <AcademicCalenda />
            </Suspense>
          }
        />
        <Route
          path="/lmu-short-programs"
          element={
            <Suspense>
              <ShortPrograms />
            </Suspense>
          }
        />
        <Route
          path="/school-of-agriculture"
          element={
            <Suspense>
              <SchoolofAgriculture />
            </Suspense>
          }
        />
        <Route
          path="/school-of-medicine"
          element={
            <Suspense>
              <SchoolofMedicine />
            </Suspense>
          }
        />
        <Route
          path="/school-of-business"
          element={
            <Suspense>
              <SchoolofBusiness />
            </Suspense>
          }
        />
        <Route
          path="/about-lmu"
          element={
            <Suspense>
              <About />
            </Suspense>
          }
        />
        <Route
          path="/lmu-departments"
          element={
            <Suspense>
              <Departments />
            </Suspense>
          }
        />
        <Route
          path="/single-courses"
          element={
            <Suspense>
              <Coursesdeatils />
            </Suspense>
          }
        />
        <Route
          path="/lmu-announcements"
          element={
            <Suspense>
              <Announcements />
            </Suspense>
          }
        />
        <Route
          path="/announcement-detail"
          element={
            <Suspense>
              <AnnouncementDetail />
            </Suspense>
          }
        />
        <Route
          path="/lmu-picture-galery"
          element={
            <Suspense>
              <Gallery />
            </Suspense>
          }
        />
        <Route
          path="/lmu-campuse-a"
          element={
            <Suspense>
              <CampusA />
            </Suspense>
          }
        />
        <Route
          path="/lmu-campuse-b"
          element={
            <Suspense>
              <CampusB />
            </Suspense>
          }
        />
        <Route
          path="/home-economic-detail"
          element={
            <Suspense>
              <HomeEconomics />
            </Suspense>
          }
        />
        <Route
          path="/nursing-detail"
          element={
            <Suspense>
              <Nursing />
            </Suspense>
          }
        />
        <Route
          path="/school-of-engineering"
          element={
            <Suspense>
              <SchoolofEng />
            </Suspense>
          }
        />
        <Route
          path="/dentistry-detail"
          element={
            <Suspense>
              <Dentistry />
            </Suspense>
          }
        />
        <Route
          path="/midwifery-detail"
          element={
            <Suspense>
              <Midwifery />
            </Suspense>
          }
        />
        <Route
          path="/crop-production-detail"
          element={
            <Suspense>
              <CropProduction />
            </Suspense>
          }
        />
        <Route
          path="/food-technology-detail"
          element={
            <Suspense>
              <FoodTechnology />
            </Suspense>
          }
        />
        <Route
          path="/lmu-registrar"
          element={
            <Suspense>
              <LMURegistra />
            </Suspense>
          }
        />
        <Route
          path="/agro-pastoral-entrepreneurship-detail"
          element={
            <Suspense>
              <AgroPastoralEntrepreneurship />
            </Suspense>
          }
        />
        <Route
          path="/animal-production-technology-detail"
          element={
            <Suspense>
              <AnimalProduction />
            </Suspense>
          }
        />
        <Route
          path="/pharmacy-technology-detail"
          element={
            <Suspense>
              <PharmacyTechnology />
            </Suspense>
          }
        />
        <Route
          path="/medical-laboratory-sciences-detail"
          element={
            <Suspense>
              <MedicalLaboratorySciences />
            </Suspense>
          }
        />
        <Route
          path="/tourism-and-travel-agency-detail"
          element={
            <Suspense>
              <TourismAndTravel />
            </Suspense>
          }
        />
        <Route
          path="/journalism-and-corperate-communication-detail"
          element={
            <Suspense>
              <Journalism />
            </Suspense>
          }
        />
        <Route
          path="/lmu-student-residences"
          element={
            <Suspense>
              <StudentAccormodations />
            </Suspense>
          }
        />
        <Route
          path="/tourism-and-hospitality-detail"
          element={
            <Suspense>
              <TourismAndHospitality />
            </Suspense>
          }
        />
        <Route
          path="/law-detail"
          element={
            <Suspense>
              <Law />
            </Suspense>
          }
        />
        <Route
          path="/marketing-detail"
          element={
            <Suspense>
              <Marketing />
            </Suspense>
          }
        />
        <Route
          path="/digital-marketing"
          element={
            <Suspense>
              <DigitalMarketing />
            </Suspense>
          }
        />
        <Route
          path="/human-resource-detail"
          element={
            <Suspense>
              <HumanResource />
            </Suspense>
          }
        />
        <Route
          path="/vocational-guidance-and-counseling-detail"
          element={
            <Suspense>
              <VocationalGuidanceAndCounselling />
            </Suspense>
          }
        />
        <Route
          path="/education-didactics-detail"
          element={
            <Suspense>
              <EducationDidatics />
            </Suspense>
          }
        />
        <Route
          path="/transport-and-logistic-management-detail"
          element={
            <Suspense>
              <TransportandLogistic />
            </Suspense>
          }
        />
        <Route
          path="/shipping-management-detail"
          element={
            <Suspense>
              <ShippingManagement />
            </Suspense>
          }
        />
        <Route
          path="/project-management-detail"
          element={
            <Suspense>
              <Projectmanagemnt />
            </Suspense>
          }
        />
        <Route
          path="/Tuition-fee"
          element={
            <Suspense>
              <Tuition />
            </Suspense>
          }
        />
        <Route
          path="/lmu-accreditaion-and-recognition"
          element={
            <Suspense>
              <LMURecongnationAndAccreditation />
            </Suspense>
          }
        />
        <Route
          path="/professional-studies-and-educational-management-detail"
          element={
            <Suspense>
              <ProfessionalStudiesAndEdcationalmanagement />
            </Suspense>
          }
        />
        <Route
          path="/faq"
          element={
            <Suspense>
              <Faq />
            </Suspense>
          }
        />
        <Route
          path="/lmu-hnd-programs"
          element={
            <Suspense>
              <HNDOverView />
            </Suspense>
          }
        />
        <Route
          path="/lmu-straight-bsc-btech"
          element={
            <Suspense>
              <StraightBSCBTechOverView />
            </Suspense>
          }
        />
        <Route
          path="/lmu-top-up-bsc-btech"
          element={
            <Suspense>
              <TopUpBSCBTechOverView />
            </Suspense>
          }
        />
        <Route
          path="/lmu-mba-msc-mtech"
          element={
            <Suspense>
              <MastersProgramOverView />
            </Suspense>
          }
        />
        <Route
          path="/lmu-administration"
          element={
            <Suspense>
              <LMUAdministrationMain />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense>
              <Error />
            </Suspense>
          }
        />
        <Route
          path="/lmu-news"
          element={
            <Suspense>
              <News />
            </Suspense>
          }
        />
        <Route
          path="/news-detail"
          element={
            <Suspense>
              <NewsBlog />
            </Suspense>
          }
        />
        <Route
          path="/lmu-contact"
          element={
            <Suspense>
              <Contact />
            </Suspense>
          }
        />
        <Route
          path="/software-engineering-detail"
          element={
            <Suspense>
              <SoftwareEng />
            </Suspense>
          }
        />
        <Route
          path="/civil-engineering-detail"
          element={
            <Suspense>
              <CivilEngineering />
            </Suspense>
          }
        />
        <Route
          path="/mechanical-engineering-detail"
          element={
            <Suspense>
              <MechanicalEngineering />
            </Suspense>
          }
        />
        <Route
          path="/banking-and-finance-detail"
          element={
            <Suspense>
              <BankingAndFinance />
            </Suspense>
          }
        />
        <Route
          path="/accounting-detail"
          element={
            <Suspense>
              <Accounting />
            </Suspense>
          }
        />
        <Route
          path="/civil-engineering-public-works-details"
          element={
            <Suspense>
              <PublicWorks />
            </Suspense>
          }
        />
        <Route
          path="/information-technology-systems-detail"
          element={
            <Suspense>
              <InformationTechSerc />
            </Suspense>
          }
        />
        <Route
          path="/electronics-engineering-detail"
          element={
            <Suspense>
              <Electronics />
            </Suspense>
          }
        />
        <Route
          path="/cisco-detail"
          element={
            <Suspense>
              <CISCO />
            </Suspense>
          }
        />
        <Route
          path="/robotics-engineering-detail"
          element={
            <Suspense>
              <Robotic />
            </Suspense>
          }
        />
        <Route
          path="/electrical-power-system-detail"
          element={
            <Suspense>
              <EPS />
            </Suspense>
          }
        />
        <Route
          path="/lmu-admission-application"
          element={
            <Suspense>
              <ApplicationForm />
            </Suspense>
          }
        />
        <Route
          path="/lmu-history"
          element={
            <Suspense>
              <LMUHistory />
            </Suspense>
          }
        />
        <Route
          path="/hardware-engineering-detail"
          element={
            <Suspense>
              <HardwareEng />
            </Suspense>
          }
        />
        <Route
          path="/database-engineering-detail"
          element={
            <Suspense>
              <DatabaseEng />
            </Suspense>
          }
        />
        <Route
          path="/networking-and-security-engineering-detail"
          element={
            <Suspense>
              <NetworkSercurity />
            </Suspense>
          }
        />
        <Route
          path="/telecommunication-engineering-detail"
          element={
            <Suspense>
              <TelecomEng />
            </Suspense>
          }
        />
        <Route
          path="/computer-graphics-and-web-detail"
          element={
            <Suspense>
              <ComputerGraphicEng />
            </Suspense>
          }
        />
        <Route
          path="/lmu-mission-and-vision"
          element={
            <Suspense>
              <LMUCoreValues />
            </Suspense>
          }
        />
        <Route
          path="/lmu-polycies"
          element={
            <Suspense>
              <LMUPolycies />
            </Suspense>
          }
        />
        <Route
          path="/lmu-president"
          element={
            <Suspense>
              <LMUPresident />
            </Suspense>
          }
        />
        <Route
          path="/lmu-teaching-staff"
          element={
            <Suspense>
              <LMULecturers />
            </Suspense>
          }
        />
        <Route
          path="/lmu-student-council"
          element={
            <Suspense>
              <LMUStudentGovernment />
            </Suspense>
          }
        />
        <Route
          path="/lmu-schools"
          element={
            <Suspense>
              <Schools />
            </Suspense>
          }
        />
        <Route
          path="/employment"
          element={
            <Suspense>
              <Heiring />
            </Suspense>
          }
        />
      </Routes>
      <Footer />
    </>
  );
}

export default Index;
